import React, { useState, useEffect } from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import InputInfoItem from "../components/InputInfoItem"
import GeneralButton from "../components/atoms/GeneralButton"
import client from "../apis/client";

const jsonOptionalOptionList = [
    "true",
    "false"
]
const jsonObjectOptionList = [
    "News"
]


const InitialContentsList = [
    {
        label: "Title",
        name: "title",
        value: "",
        errorMessage: "",
        multipleLines: false,
        required: true,
    },
    {
        label: "Message",
        name: "message",
        value: "",
        errorMessage: "",
        multipleLines: true,
        required: true,
    },
    {
        label: "SubTitle(iOS)",
        name: "sub_title",
        value: "",
        errorMessage: "",
        multipleLines: true,
        required: true,
    },
]

const InitialJsonList = [
    {
        label: "Optional",
        name: "optional",
        value: "",
        valueIsBool: true,
        errorMessage: "",
        multipleLines: false,
        required: true,
    },
    {
        label: "Object",
        name: "obj",
        value: "",
        valueIsBool: false,
        errorMessage: "",
        multipleLines: false,
        required: false,
    },
    {
        label: "ObjctPK",
        name: "obj_pk",
        value: "",
        valueIsBool: false,
        errorMessage: "",
        multipleLines: false,
        required: false,
    },
]


const PushManagementPage = () => {

    const [ contentsList, setContentsList ] = useState( InitialContentsList )
    const [ jsonList, setJsonList ] = useState( InitialJsonList )

    useEffect( () => {
        authenticate()
    }, [] )

    const authenticate = () => {
        client.get(`/api/user_authenticate/`)
            .then( res => console.log( res ) )
            .catch( error => {
                console.log( error )
                window.history.back()
            } )
    }

    const onChangeContents = ( name: string, value: string ) => {
        const newContentsList = contentsList.map( content =>
            content.name === name ?
                { ...content, value } :
                content
        )
        setContentsList( newContentsList )
    }

    const onChangeJson = ( name: string, value: string ) => {
        const newJsonList = jsonList.map( json =>
            json.name === name ?
                { ...json, value } :
                json
        )
        setJsonList( newJsonList )
    }


    const validateContents = () => {
        let isValid = true
        contentsList.forEach( contents => {
            if( !contents.value ) {
                alert( contents.label + "が空欄です" )
                isValid = false
            }
        } )
        return isValid
    }

    const validateJson = () => {
        let isValid = true
        const optional = jsonList.filter( json => json.name === "optional" )[0].value
        if( !jsonOptionalOptionList.includes( optional ) ) {
            alert( "optionalの値が不正です" )
            isValid = false
        }
        const object = jsonList.filter( json => json.name === "obj" )[0].value
        if( object && !jsonObjectOptionList.includes( object ) ) {
            alert( "objectの値が不正です" )
            isValid = false
        }
        const obj_pk = jsonList.filter( json => json.name === "obj_pk" )[0].value
        if( !(0 < Number( obj_pk )) ) {
            alert( "objectPKの値が不正です" )
            isValid = false
        }
        if( object && !Number( obj_pk ) ) {
            alert( "objectのPKを指定してください" )
            isValid = false
        }
        return isValid
    }

    const submit = async () => {
        const contentIsValid = validateContents()
        const jsonIsValid = validateJson()
        if( !contentIsValid || !jsonIsValid ) return
        const data = new FormData
        contentsList.forEach( content =>
            data.append( content.name, content.value )
        )
        jsonList.forEach( json =>
            json.valueIsBool ?
                data.append( json.name, json.value === "true" ? true : false as any ):
                json.value && data.append( json.name, json.value )
        )
        console.log( ...(data as any).entries() )
        await client.post( `/api/push_all_users/`, data)
            .then( () => alert( "送信成功しました" ) )
            .catch( e => { console.log( e ); alert( "送信失敗しました" ) } )
    }


    return (
        <Layout>
            <>
            <SEO title="PushManagement" />
            <div style={ { marginTop: 32 } }>
                <ul>
                    { contentsList.map( contents =>
                        <InputInfoItem
                            label={ contents.label + ( contents.required ? "(必須)" : "" ) }
                            name={ contents.name }
                            value={ contents.value }
                            errorMessage={ contents.errorMessage }
                            onChange={ onChangeContents }
                            multipleLines={ contents.multipleLines } />
                    ) }
                </ul>
                <div style={ { marginLeft: 16 } }>
                    <h2 style={ { marginTop: 16, marginBottom: 8 } }>JSON</h2>
                    <p>objectはNews or "", optionalはtrue or false</p>
                </div>
                <ul>
                    { jsonList.map( input =>
                        <InputInfoItem
                            label={ input.label + ( input.required ? "(必須)" : "" ) }
                            name={ input.name }
                            value={ input.value }
                            errorMessage={ input.errorMessage }
                            onChange={ onChangeJson }
                            multipleLines={ input.multipleLines } />
                    ) }
                </ul>
                <p style={ { textAlign: "center" } }>
                    <GeneralButton
                        onClick={ () => submit() }
                        title="送信"
                        style={ { margin: "auto", marginTop: 24, width: "70%" } } />
                </p>
            </div>
            </>
        </Layout>
    )
}

export default PushManagementPage
