import React, { FC } from "react"
import Styles from "../styles/InfoItem.module.scss";
import TextareaAutosize from 'react-textarea-autosize';

type Props = {
    label: string,
    name: string,
    value: string,
    placeholder?: string
    errorMessage: string,
    onChange: ( name: string, value: string ) => void,
    multipleLines: boolean
}

const InputInfoItem: FC<Props> = ( {
    label,
    name,
    value,
    placeholder,
    errorMessage,
    onChange,
    multipleLines = false
} ) =>
    <li className={ Styles.info_item }>
        <span className={ Styles.item_label }>{ label }</span>
        <div className={ Styles.input_wrapper }>
            { multipleLines ?
                <TextareaAutosize
                    onChange={ ( e ) => onChange( name, e.target.value ) }
                    name={ name }
                    placeholder={ placeholder }
                    autoFocus={ false }
                    value={ value || "" }
                    maxRows={ 10 }
                    minRows={ 2 }
                    className={ Styles.textarea } /> :
                <input
                    className={ Styles.info_input }
                    placeholder={ placeholder }
                    name={ name }
                    onChange={ ( e ) => onChange( name, e.target.value ) }
                    value={ value } />
            }
            { errorMessage &&
                <p className={ Styles.error }>{ errorMessage }</p> }
        </div>
    </li>

// export const SelectInputInfoItem = ( { label, name, value, errorMessage, onChange, multipleLines = false } ) => {

// 	return (
// 		<li className={ Styles.info_item }>
// 			<span className={ Styles.item_label }>{ label }</span>
// 			<div className={ Styles.input_wrapper }>
// 				{ multipleLines ?
// 					<TextareaAutosize
// 						onChange={ ( e ) => onChange( name, e.target.value ) }
// 						name={ name }
// 						autoFocus={ false }
// 						// inputRef={tag => (textarea.current = tag)}
// 						value={ value || "" }
// 						maxRows={ 10 }
// 						minRows={ 2 }
// 						className={ Styles.textarea } /> :
// 					<input className={ Styles.info_input } name={ name } onChange={ ( e ) => onChange( name, e.target.value ) } value={ value } />
// 				}
// 				<p className={ Styles.error }>{ errorMessage }</p>
// 			</div>
// 		</li>
// 	)
// }

export default InputInfoItem
